<template>
  <div class="project" :class="{ 'isEdit' : isEdit }">
    <h4 v-if="!isEdit">
      Crea un progetto ed assegnalo a una o più campagne impostando i parametri
    </h4>
    <div>
      <div class="filters-container pt">
        <div class="project-icon">
          <font-awesome-icon icon="tag" class="tag fa-xl" style="min-width: 40px" />
          <Multiselect
            v-model="filters.projectName"
            :disabled="filters.projectName[0]"
            mode="tags"
            :max="1"
            :can-clear="true"
            :close-on-select="true"
            :clear-on-select="true"
            :clear-on-blur="true"
            :searchable="true"
            :create-option="true"
            :options="tags"
            placeholder="Nome del progetto. Es. &quot;TalentWeek2023&quot;"
            no-options-text="Scrivi il nome del progetto e premi enter..."
            @create="handleTagCreate"
          >
            <template #tag="{ option, handleTagRemove }">
              <div class="multiselect-tag" :class="{'duplicate': duplicateName}" @click="handleTagRemove(option, $event)">
                <span>{{ option.value }}</span>
              </div>
            </template>
          </Multiselect>
          <font-awesome-icon v-show="filters.projectName[0] && !isEdit" icon="xmark" class="close" @click="filters.projectName = []; tags = []; duplicateName = false" />
        </div>
        <div v-if="!isEdit">
          <input v-model="filters.published" type="checkbox" :checked="filters.published" @click="filters.published = !filters.published">
          <span style="font-size:11px;text-transform:uppercase">Pubblica il progetto dopo la creazione</span>
        </div>
      </div>
      <div class="filters-container">
        <material-date-picker
          :date="filters.startDate"
          label="Data di inizio"
          :bordered="true"
          :required="false"
          @update:date="filters.startDate = $event; filters.startDate > filters.endDate ? filters.endDate = filters.startDate : ''"
        />
        <material-date-picker
          :date="filters.endDate"
          label="Data di fine"
          :disabled="!filters.startDate"
          :bordered="true"
          :required="false"
          :lower-limit="filters.startDate"
          @update:date="filters.endDate = $event"
        />
      </div>
      <div class="filters-container">
        <Multiselect
          :key="filters.company ? 'azienda_coid_' + filters?.company?.coid : 'no_selection'"
          v-model="filters.company"
          placeholder="Cerca l'azienda"
          :object="true"
          :filter-results="false"
          :min-chars="1"
          :resolve-on-load="false"
          :delay="250"
          :searchable="true"
          label="co_name"
          track-by="coid"
          value-prop="coid"
          :clear-on-select="true"
          :no-options-text="noResults ? 'Nessuna azienda corrisponde alla ricerca effettuata' : 'Inizia a digitare...'"
          :options="async (query) => await searchCompany(query)"
          @select="getCampaigns"
        />
        
        <!-- <div :key="'checkbox_container_' + counter" class="checkbox-container-flex">
          <div>
            <input v-model="filters.correnti" class="correnti" type="checkbox" :checked="filters.correnti" @change="changeStatus('correnti')">
            <span>Correnti</span>
          </div>
          <div>
            <input v-model="filters.archiviate" class="archiviate" type="checkbox" @change="changeStatus('archiviate')">
            <span>Archiviate</span>
          </div>
          <div>
            <input v-model="filters.scadute" class="scadute" type="checkbox" @change="changeStatus('scadute')">
            <span>Scadute</span>
          </div>
        </div> -->
      </div>

      <div v-if="!(filters?.company?.coid) && (filters.companiesBackup && filters.companiesBackup.length > 0) || filters.projectCompaniesBackup && filters.projectCompaniesBackup.length > 0" class="finalAccordion box" role="presentation">
        <div v-if="filters.companiesBackup && filters.companiesBackup.length > 0" class="accordion-item" :class="{ 'is-active': preview.campaigns }">
          <div class="accordion-item-header" @click="toggle('campaigns')">
            <span class="accordion-item-header-element chips"><span>{{ filters?.companies?.length === 1 ? '1 campagna associata al progetto' : filters?.companies?.length + ' campagne associate al progetto' }}</span></span>
            <span class="accordion-item-header-element" />
            <span class="accordion-item-trigger-icon" />
          </div>
          <transition name="accordion-item" @enter="startTransition" @after-enter="endivransition" @before-leave="startTransition" @after-leave="endivransition">
            <div v-if="preview.campaigns" class="accordion-item-details">
              <div class="accordion-item-details-inner">
                <campaignsTable
                  v-if="!filters?.company?.coid && filters.companiesBackup.length > 0"
                  :items="filters.companies"
                  :selected-items="filters.companies"
                  :final-preview="true"
                  :is-edit="isEdit"
                  :correnti="filters.correnti"
                  :archiviate="filters.archiviate"
                  :scadute="filters.scadute"
                  @update:remove="removeCampaignFromProject($event)"
                  @update:correnti="filters.correnti = $event; changeStatus('correnti')"
                  @update:archiviate="filters.archiviate = $event; changeStatus('archiviate')"
                  @update:scadute="filters.scadute = $event; changeStatus('scadute')"
                />
              </div>
            </div>
          </transition>
        </div>
        <div v-if="filters.projectCompaniesBackup && filters.projectCompaniesBackup.length > 0" class="accordion-item" :class="{ 'is-active': preview.companies }">
          <div class="accordion-item-header" @click="toggle('companies')">
            <div style="display: flex; gap: 1rem">
              <span class="accordion-item-header-element chips">
                <span v-if="filters.projectCompaniesBackup.length === 1">
                  {{ filters.projectCompaniesBackup.length }} azienda associata al progetto
                </span>
                <span v-else>
                  {{ filters.projectCompaniesBackup.length }} aziende associate al progetto
                </span>
              </span>
              <span class="accordion-item-header-element chips">
                <!-- if at least 1 company is selected in the project -->
                <span v-if="filters.projectCompaniesBackup.length > 0">
                  {{ getTotalExceptionCount() === 1
                    ? '1 eccezione'
                    : getTotalExceptionCount() + ' eccezioni associate al progetto'
                  }}
                </span>
              </span>
            </div>
            <span class="accordion-item-trigger-icon" />
          </div>
          <transition name="accordion-item" @enter="startTransition" @after-enter="endivransition" @before-leave="startTransition" @after-leave="endivransition">
            <div v-if="preview.companies" class="accordion-item-details">
              <div class="accordion-item-details-inner">
                <!-- altro accordion con listato aziende + eccezioni    -->
                <div class="accordion box custom-table" role="presentation">
                  <div v-for="company in filters.projectCompaniesBackup" :key="company.coid" class="accordion-item" :class="{ 'is-active': filters.projectCompanyOpened === company.coid }">
                    <div
                      class="accordion-item-header"
                      @click="toggleProjectCompany(company.coid)"
                    >
                      <span>{{ company.coid }} - {{ company.companyName }} - {{ getCampaignsExcludedByCoid(company.coid).length }} eccezioni</span>
                      <span v-show="getCampaignsExcludedByCoid(company.coid).length" class="accordion-item-trigger-icon" />

                      <font-awesome-icon
                        class="deleteItem deleteCompany toDelete"
                        icon="trash-can"
                        @click="removeCompanyFromProject(company.coid)"
                      />
                    </div>
                    <transition
                      name="accordion-item"
                      @enter="startTransition"
                      @after-enter="endivransition"
                      @before-leave="startTransition"
                      @after-leave="endivransition"
                    >
                      <div v-if="filters.projectCompanyOpened === company.coid && getCampaignsExcludedByCoid(company.coid).length > 0" class="accordion-item-details">
                        <div class="accordion-item-details-inner">
                          <campaignsTable
                            :items="getCampaignsExcludedByCoid(company.coid, true)"
                            :selected-items="filters.projectCompanies"
                            :final-preview="true"
                            :is-edit="isEdit"
                            :correnti="filters.correnti"
                            :archiviate="filters.archiviate"
                            :scadute="filters.scadute"
                            @update:remove="removeCampaignFromCompanyProject($event)"
                            @update:correnti="filters.correnti = $event; changeStatus('correnti')"
                            @update:archiviate="filters.archiviate = $event; changeStatus('archiviate')"
                            @update:scadute="filters.scadute = $event; changeStatus('scadute')"
                          />
                        </div>
                      </div>
                    </transition>
                  </div>
                </div>
              </div>
            </div>
          </transition>
        </div>
      </div>
       
      <div v-if="filters?.company && campaignsOpt?.data?.length > 0" class="tabs">
        <ul class="tabs-nav">
          <li class="campaigns" :class="{'tab-selected' : tab === 1}" @click="tab = 1">
            ASSOCIA PER CAMPAGNA
          </li>
          <li class="companies" :class="{'tab-selected' : tab === 2}" @click="tab = 2">
            ASSOCIA PER AZIENDA
          </li>
        </ul>

        <div class="filters-container" :style="{ 'flex-direction': tab === 2 ? 'column' : 'row' }">
          <div v-if="tab === 1" class="filters-container-alert manageCampaign">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
              <title>plus-circle</title>
              <path d="M17,13H13V17H11V13H7V11H11V7H13V11H17M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2Z" />
            </svg>
            <span>Seleziona una o più campagne da associare al progetto.</span>
          </div>
          <div v-else class="filters-container-alert removeCampaign">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
              <title>minus-circle</title>
              <path d="M17,13H7V11H17M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2Z" />
            </svg>
            <span>Tutte le campagne presenti e future delle aziende selezionate sono automaticamente associate al progetto. <p>Per escludere una o più campagne, selezionane le checkbox.</p></span>
          </div>
          <div v-if="tab === 2" class="addAllBtn">
            <material-button
              :text="dynamicButtonText()"
              class="pure-material-button-contained info small"
              @click="addCompaniesSelected"
            />
          </div>
        </div>
        
        <campaignsTable
          :exceptions="tab === 2"
          :total-pages-items="totalPages"
          :total-elements="totalElements"
          :page-size="itemsPerPage"
          :page-number="currentPage"
          :items="campaignsOpt.data"
          :selected-items="tab === 1 ? filters.companies : getCampaignsExcludedByCoid(filters.company?.coid)"
          :campaign-title="campaignsOpt?.data[0]?.company"
          :coid="campaignsOpt?.data[0]?.coid"
          :final-preview="false"
          @update:selected="tab === 1 ? filters.companies = $event : setCampaignsExcludedByCoid($event)"
          @update:page="(n) => getCampaigns({page: n })"
          @searchByCoidOrTitle="(obj) => getCampaigns(obj)"
          @addCompaniesSelected="addCompaniesSelected"
        />
        <div class="tabs-stage">
          <div v-if="tab === 1" id="tab-1">
            <!-- Tabella finale dopo la selezione -->
            <campaignsTable
              v-if="!filters?.company?.coid && filters.companies.length > 0"
              :items="filters.companies"
              :selected-items="filters.companies"
              :final-preview="true"
              :is-edit="isEdit"
              @updateRemove="removeItem($event)"
            />
          </div>
          <div v-if="tab === 2" id="tab-2">
            <!-- Tabella finale dopo la selezione -->
            <campaignsTable
              v-if="!(getCampaignsExcludedByCoid(filters.company?.coid)?.length > 0)"
              :items="getCampaignsExcludedByCoid(filters.company?.coid)"
              :selected-items="getCampaignsExcludedByCoid(filters.company?.coid)"
              :final-preview="true"
              :is-edit="isEdit"
              @updateRemove="removeItem($event)"
            />
          </div>
        </div>
      </div>
      
      <div class="pt right">
        <material-button
          v-if="!isEdit"
          text="Crea progetto"
          class="pure-material-button-contained info small"
          :class="{'disabled-btn': !filters.projectName[0] || filters.companies?.length === 0}"
          @click="createProject"
        />
        <material-button
          v-if="isEdit"
          text="Salva progetto"
          class="pure-material-button-contained info small"
          @click="modifyProject"
        />
        <!-- :class="{'disabled-btn': !filters.projectName[0] || filters.companies?.length === 0}" -->
        <material-button
          v-if="isEdit"
          text="Cancella progetto"
          class="pure-material-button-contained error small"
          :class="{'disabled-btn': !filters.projectName[0] || filters.companies?.length === 0}"
          style="background-color:  rgb(152, 23, 57)"
          @click="deleteProject"
        />
      </div>
    </div>
  </div>
</template>
<script>
import campaignsTable from './campaignsProjectTable.vue'
import { sbapibackoffice } from '@/api'
import { inject, watch, onMounted, onBeforeMount } from 'vue'
import { ref, reactive } from '@vue/reactivity'
import Multiselect from '@vueform/multiselect'
import '@vuepic/vue-datepicker/dist/main.css'
require('dayjs/locale/it')
import dayjs from 'dayjs'
import { spinnerStore } from '@/stores/spinner'
dayjs.locale('it')
import { navbarStore } from '@/stores/navbar'
const utc = require('dayjs/plugin/utc')
const timezone = require('dayjs/plugin/timezone')

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.tz.setDefault('Europe/Rome') 
export default {
  components: { Multiselect, campaignsTable },
  props: {
    projectSelected : {
      type: Array,
      default: () => []
    },
    isEdit : {
      type: Boolean,
      default: () => false
    }
  },
  emits: ['modifyProject', 'deleteProject'],
  setup(props, { emit }) {
    let tab = ref(1)
    let currentPage = ref(1)
    let itemsPerPage= 15
    let totalPages= ref(0)
    let totalElements = ref(0)
    const duplicateName = ref(false)
    const spinner = spinnerStore()
    const navbar = navbarStore()
    const showPreview = ref(false)
    const search = ref('')
    const selections = ref([])
    const toast = inject('$toast')
    const tags = ref([])
    const noResults = ref(false)
    const campaignsOpt= ref({})
    const counter=ref(1)
    const filters = reactive({
      published:true,
      projectName:[],
      startDate :dayjs().subtract(6, 'month').startOf('day').toDate(),
      endDate: dayjs().startOf('day').toDate(),
      archiviate: true,
      scadute: true,
      correnti: true,
      company: {},
      companies: [],
      companiesBackup: [],
      projectCompanies: [],
      projectCompaniesBackup: [],
      projectCompanyOpened: -1
    })
    watch(() => filters.company, () => {
      if(!filters.company || !filters?.company.coid) {
        campaignsOpt.value.data = {}
      }
    })
    onBeforeMount(() => {
      console.log('projectSelected', props.projectSelected)

      const deepClone = (inObject) => {
        let outObject, value, key

        if (typeof inObject !== 'object' || inObject === null)
          return inObject

        if (inObject instanceof Map) {
          outObject = new Map(inObject)
          for ([key, value] of outObject)
            outObject.set(key, deepClone(value))
        } else if (inObject instanceof Set) {
          outObject = new Set()
          for (value of inObject)
            outObject.add(deepClone(value))
        } else if (inObject instanceof Date) {
          outObject = new Date(+inObject)
        } else {
          outObject = Array.isArray(inObject) ? [] : {}
          for (key in inObject) {
            value = inObject[key]
            outObject[key] = deepClone(value)
          }
        }
        return outObject
      }

      if(props.isEdit) {
        filters.projectName = props.projectSelected.projectName
        tags.value = props.projectSelected.projectName
        filters.startDate = props.projectSelected.startDate
        filters.endDate = props.projectSelected.endDate
        filters.companies = props.projectSelected.companies
        filters.companiesBackup = props.projectSelected.companies
        filters.projectCompanies = props.projectSelected.projectCompanies
        // filters.projectCompaniesBackup = JSON.parse(JSON.stringify(props.projectSelected.projectCompanies))
        // filters.projectCompaniesBackup = { ...props.projectSelected.projectCompanies }
        filters.projectCompaniesBackup = deepClone(props.projectSelected.projectCompanies)
      }
    })
    onMounted(() => {
      spinner.show()
      navbar.title = 'Crea progetto'
      spinner.hide()
    })

    const getCampaignsExcludedByCoid = (coid, applyStatusFilter) => {
      // if applyStatusFilter is true, filter campaigns by status
      let all = filters.projectCompaniesBackup.find(c => c.coid === coid)?.excludeCampaigns

      if (applyStatusFilter) {
        all = all.filter(c => {
          if (filters.correnti && c.status === 1) return true
          if (filters.scadute && c.status === 2) return true
          if (filters.archiviate && c.status === 3) return true
          
          return false
        })
      }

      return all
    }

    const searchCompany = async (query) => {
      currentPage.value = 1
      showPreview.value = false
      noResults.value = false

      try {
        if (query) {
          let results = await sbapibackoffice.get('companieslist?initialLettersOfCompanies=' + query)
          if (results?.data[0]?.coid) {
            return results.data
          }
          noResults.value = true
          return []
        }
        return []
      } catch (error) {
        toast.error('Errore durante la ricerca dell\'azienda: '+ error.message)
        noResults.value = true
        return []
      }
    }
    /*  const updateStatus = () => {
      const status = []
      if (filters.correnti) {
        status.push(1)
      }
      if (filters.scadute) {
        status.push(2)
      }

      if (filters.archiviate) {
        status.push(3)
      }
      console.log('status', status, status.toString())
      return status.toString()
    } */
    const getCampaigns = async () => {
      const deepClone = (inObject) => {
        let outObject, value, key

        if (typeof inObject !== 'object' || inObject === null)
          return inObject

        if (inObject instanceof Map) {
          outObject = new Map(inObject)
          for ([key, value] of outObject)
            outObject.set(key, deepClone(value))
        } else if (inObject instanceof Set) {
          outObject = new Set()
          for (value of inObject)
            outObject.add(deepClone(value))
        } else if (inObject instanceof Date) {
          outObject = new Date(+inObject)
        } else {
          outObject = Array.isArray(inObject) ? [] : {}
          for (key in inObject) {
            value = inObject[key]
            outObject[key] = deepClone(value)
          }
        }
        return outObject
      }

      showPreview.value = false

      if (!filters?.company?.coid) {
        console.log('Nessun coid')
        // .coid is null or undefined, filter item in table
        // status 1 = correnti
        // status 2 = scadute
        // status 3 = archiviate
        filters.companies = filters.companiesBackup.filter(c => {
          if (filters.correnti && c.status === 1) return true
          if (filters.scadute && c.status === 2) return true
          if (filters.archiviate && c.status === 3) return true

          return false
        })

        // for each filters.projectCompanies filter excludeCampaigns
        filters.projectCompanies = deepClone(filters.projectCompaniesBackup)
        filters.projectCompanies.forEach(company => {
          company.excludeCampaigns = company.excludeCampaigns.filter(c => {
            if (filters.correnti && c.status === 1) return true
            if (filters.scadute && c.status === 2) return true
            if (filters.archiviate && c.status === 3) return true

            return false
          })
        })

        return
      }
      /*  console.log('AFTER ELSE')
      spinner.show()
  
      if (obj && obj.page) {
        currentPage.value = Number(obj.page)
      }
  
      const status = updateStatus()
      const coid = filters.company.coid

      try {
        const query = `v2/campaigns?coid=${coid}&status=${status}${obj && obj.search ? (isNaN(obj.search) ? `&search=${obj.search}` : `&cid=${obj.search}`) : ''}&page=${Number(currentPage.value) - 1}&size=${itemsPerPage}&sort=dataCreazione,desc`
    
        const results = await sbapibackoffice.get(query)
    
        if (results?.data?.content?.length > 0) {
          totalElements.value = results.data.totalElements
          totalPages.value = results.data.totalPages
      
          results.data.content.forEach(content => {
            content.coid = coid
            content.company = filters.company.co_name
          })
      
          campaignsOpt.value.data = results.data.content
      
          if (Number(currentPage.value) === 1 && !obj?.page && !obj?.search) {
            const totalCampaigns = results.data.totalElements
            toast.success(`Trovate ${totalCampaigns} ${totalCampaigns === 1 ? 'campagna' : 'campagne'}`)
          }

          filters.projectCompanyOpened = -1
          preview.companies = false
        } else {
          await searchCompany('')
      
          if (obj.search) {
            toast.warning(`Nessuna campagna trovata per la ricerca "${obj.search}"`)
          } else {
            toast.warning('Nessuna campagna trovata per l\'azienda selezionata')
            campaignsOpt.value = {}
            filters.company = ''
          }
        }
      } catch (err) {
        toast.error(`Impossibile recuperare i dati della campagna: ${err}`)
      }
  
      spinner.hide() */
    }

    const removeItem = (element) => {
      let index = filters.companies.findIndex(e => e.cid === element.cid)

      if (index !== -1) {
        filters.companies.splice(index, 1)
      }
    }

    const setCampaignsExcludedByCoid = (obj) => {
      console.log('setCampaignsExcludedByCoid', obj)
      let index = filters.projectCompanies.findIndex(c => c.coid === filters.company.coid)
      if (index !== -1) {
        filters.projectCompanies[index].excludeCampaigns = obj
      } else {
        filters.projectCompanies.push({
          coid: filters.company.coid,
          companyName: filters.company.co_name,
          excludeCampaigns: obj
        })
      }
    }

    const addCompaniesSelected = () => {
      console.log('addCompaniesSelected', filters.projectCompanies)
      var cids = null
      if(tab.value === 1) {
        cids = new Set(filters.companies.map(d => d.cid))
        filters.companies = [...filters.companies, ...selections.value.filter(d => !cids.has(d.cid))]
        console.log(filters.companies)
      } else {
        // search if company is already in the list, if not add it
        let findCompany = filters.projectCompanies.find(c => c.coid === filters.company.coid)
        if (!findCompany) {
          // filters.company type = { co_name: string, coid: number }
          // filters.projectCompanies type = { coid: number, companyName: string, excludeCampaigns: { cid: number, title: string, status: number }[] }
          filters.projectCompanies.push({
            coid: filters.company.coid,
            companyName: filters.company.co_name,
            excludeCampaigns: [] // getCampaignsExcludedByCoid(filters.company.coid)
          })
        }

        // console.log('addCompaniesSelected', filters.projectCompanies)

        // cids = []

        // filters.projectCompanies.forEach(company => {
        //   cids.push(company.excludeCampaigns.map(c => c.cid))
        // })

        // filters.projectCompanies = [...filters.projectCompanies, ...selections.value.filter(d => !cids.has(d.cid))]
      }
      selections.value = []
      filters.company = {}
      campaignsOpt.value = {}
    }
    const isChecked = (cid) => {
      return filters.companies.some(a => a.cid === cid) || selections.value.some(a => a.cid === cid)
    }
    const resetProject = () => {
      filters.published = true
      filters.projectName = []
      filters.startDate = dayjs().subtract(6, 'month').startOf('day').toDate()
      filters.endDate = dayjs().startOf('day').toDate()
      filters.archiviate = false
      filters.scadute = false
      filters.correnti = true
      filters.company = {}
      filters.companies = []
      filters.projectCompanies = []
      tags.value = []
      selections.value = []
      campaignsOpt.value = {}
    }
    const deleteProject = () =>{
      console.log('Cancella progetto')
      emit('deleteProject')
    }
    const modifyProject = () =>{
      emit('modifyProject', filters)
    }
    const createProject = async () => {
      duplicateName.value = false
      if (!filters.projectName[0]) {
        toast.warning('Inserisci il nome del progetto')
        return
      }
      if (filters.companies?.length === 0) {
        toast.warning('Seleziona almeno una campagna per procedere con la creazione del progetto')
        return
      }
      spinner.show()
      const params = {
        name: filters.projectName[0],
        startDate: dayjs(filters.startDate).format('YYYY-MM-DD'),
        endDate: dayjs(filters.endDate).format('YYYY-MM-DD'),
        cids: filters.companies.map(c => c.cid),
        published: filters.published
      }

      sbapibackoffice
        .post('/projects', params)
        .then(response => {
          toast.success('Progetto creato con successo')
          console.log('ok', response.data)
          resetProject()
        })
        .catch(err => {
          toast.clear()
          if (err.response && err.response.data) {
            let messageCode = err.response.data.messageCode
            let errorMessage = 'Si è verificato un errore: '
            if ( err.response.status === 406) {
              switch (messageCode) {
                case 'PRJ-001':
                  errorMessage += 'il nome del progetto deve essere specificato'
                  break
                case 'PRJ-002':
                  errorMessage += 'la data di inizio del progetto deve essere specificata'
                  break
                case 'PRJ-003':
                  errorMessage += 'la data di fine del progetto deve essere specificata'
                  break
                case 'PRJ-004':
                  errorMessage += 'la data di inizio deve essere precedente alla data di fine del progetto'
                  break
                case 'PRJ-005':
                  errorMessage += 'esiste già un progetto con il nome ' + filters.projectName[0]
                  duplicateName.value = true
                  break
                case 'PRJ-006':
                  errorMessage += 'alcuni dei cid da assegnare al progetto non esistono'
                  break
                case 'PRJ-009':
                  errorMessage += 'sono presenti campagne (cid) duplicate'
                  break
              }
            }
            toast.error(errorMessage)
          } else {
            toast.error('Si è verificato un errore')
          }
        })
        .finally(() => {
          spinner.hide()
        })
    }

    const changeStatus = (input) => {
      if (!filters.correnti && !filters.archiviate && !filters.scadute) {
        filters[input] = true
        counter.value++
        toast.warning('Seleziona almeno una tipologia di status per la ricerca delle campagne')
      } else {
        getCampaigns({ page: 1 })
      }
    }

    const handleTagCreate = async (option, select$) => {
      if(option && option.label){
        await sbapibackoffice
          .get('/projects/check?name=' + option.label)
          .then(response => {
            if (response.data.exists) {
              toast.error('Il nome progetto già esiste')
              setTimeout(() => {
                filters.projectName = []
                select$.clear()
                select$.close()
              }, 50)
              return
            } else {
              tags.value.push(option)
              setTimeout(() => {
                select$.select(option)

              }, 50)
            }
          })
          .catch(err => {
            toast.error(err)
          })
          .finally(()=>{
            select$.search = ''
          })
      }
    }

    const preview = reactive({
      campaigns: false,
      companies: false
    })
    const toggle = (el) => {
      console.log(el)
      if (preview[el]) {
        preview[el] = false
      } else {
        preview.campaigns = false
        preview.companies = false
        preview[el] = true
      }
    }

    const dynamicButtonText = () => {
      if (getCampaignsExcludedByCoid(filters.company?.coid)?.length > 0) {
        return `Aggiungi l'azienda ${filters.company?.co_name} con ${getCampaignsExcludedByCoid(filters.company?.coid)?.length} ${(getCampaignsExcludedByCoid(filters.company?.coid)?.length > 1) ? 'eccezioni' : 'eccezione'} al progetto`
      }
      return `Aggiungi l'azienda ${filters.company?.co_name} al progetto`
    }

    const getTotalExceptionCount = () => {
      let total = 0
      filters.projectCompanies.forEach(company => {
        total += company.excludeCampaigns?.length ?? 0
      })
      return total
    }

    const removeCompanyFromProject = (coid) => {
      let index = filters.projectCompanies.findIndex(c => c.coid === coid)
      if (index !== -1) {
        filters.projectCompanies.splice(index, 1)
      }
    }

    const removeCampaignFromCompanyProject = ({ cid }) => {
      let index = filters.projectCompanies.findIndex(c => c.coid === filters.projectCompanyOpened)
      if (index !== -1) {
        let indexCampaign = filters.projectCompanies[index].excludeCampaigns.findIndex(c => c.cid === cid)
        if (indexCampaign !== -1) {
          filters.projectCompanies[index].excludeCampaigns.splice(indexCampaign, 1)
        }
      }
    }

    const removeCampaignFromProject = ({ cid }) => {
      let index = filters.companies.findIndex(c => c.cid === cid)
      if (index !== -1) {
        filters.companies.splice(index, 1)
      }
    }

    const toggleProjectCompany = (coid) => {
      // reset filters
      filters.correnti = true
      filters.archiviate = true
      filters.scadute = true
      filters.projectCompanyOpened = filters.projectCompanyOpened === coid ? -1 : coid
    }

    return { toggleProjectCompany, removeCampaignFromProject, removeCompanyFromProject, removeCampaignFromCompanyProject, getTotalExceptionCount, dynamicButtonText, setCampaignsExcludedByCoid, getCampaignsExcludedByCoid, preview, toggle, handleTagCreate, currentPage, itemsPerPage, totalPages, totalElements, duplicateName, changeStatus, counter, createProject, modifyProject, deleteProject, removeItem, noResults, showPreview, isChecked, addCompaniesSelected, search, selections, getCampaigns, campaignsOpt, tags, filters, searchCompany, tab }
  }
}
</script>
<style src="@vueform/multiselect/themes/default.css"></style>
<style lang="scss">
@use "../assets/scss/projects";

.tabs {
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 4px;
  ul.tabs-nav {
    display: flex;
    align-items: center;
    li {
      cursor: pointer;
      display: flex;
      align-items: center;
      flex: 1 1 100%;
      border-bottom: 1px solid rgba(255, 255, 255, 0.5);
      padding: 15px 10px;
      box-shadow: none;
      background: rgba(var(--pure-material-onsurface-rgb, 0, 0, 0), 0.1);
      color: rgba(255, 255, 255, 0.7);
   
    }
    li.tab-selected {
      color: white;
      border-bottom: 1px solid transparent;
      background: transparent;
      opacity: 1;
    }
    
    li.campaigns {
      border-right: 1px solid rgba(255, 255, 255, 0.5);
    }
  }
  .tabs-stage {
    padding: 20px 10px;
  }
}

.project {
  .finalAccordion {
    .accordion-item {
      margin-bottom: 10px;
      border-radius: 4px;
      border: 1px solid rgba(255, 255, 255, 0.5);

    }

    .accordion-item-header {
      position: relative;
      color: white;
      display: flex;
      align-items: center;
      padding: 0 10px;
      height: 40px;
      border-bottom: 1px solid white;

      .accordion-item-header-element {
        padding-right: 10px;
        ;
        display: flex;
        flex: 1;

        &.chips {
          align-items: center;

          span {
            background-color: #118294;
            font-variant: small-caps;
            color: white;
            border-radius: 4px;
            width: fit-content;
            padding: 2.5px 10px;
          }
        }

      }

      .accordion-item-trigger {
        width: 100%;
        text-align: left;
        background-color: transparent;
        border: none;
      }

      .accordion-item-trigger-icon {
        display: block;
        position: absolute;
        top: 0;
        right: 1.25rem;
        bottom: 0;
        margin: auto;
        width: 8px;
        height: 8px;
        border-right: 2px solid #ffffff;
        border-bottom: 2px solid #ffffff;
        transform: translateY(-2px) rotate(45deg);
        transition: transform 0.2s ease;
      }

    }

    .accordion-item {
      .accordion-item-header:last-child {
        border-bottom: 0;
      }

      &.is-active .accordion-item-trigger-icon {
        transform: translateY(2px) rotate(225deg);
      }
    }

  }

  .accordion-item-details {
    overflow: hidden;
  }

  .accordion-item-enter-active,
  .accordion-item-leave-active {
    will-change: height;
    transition: height 0.2s ease;
  }

  .accordion-item-enter,
  .accordion-item-leave-to {
    height: 0 !important;
  }

  .accordion-item-trigger {
    padding: 0.75rem 1.25rem;
  }

  .filters-container-alert {
    display: flex;
    align-items: center;
    margin: 15px 10px 5px 10px;
    border-radius: 4px;
    padding: 0.7rem 1rem !important;
    width: calc(100% - 20px);
    font-weight: 600;
    font-size: 11px;
    text-transform: uppercase;
    text-transform: small-caps;

    span {
      margin-left: 10px;
      text-align: left;
    }

    svg {
      width: 25px;
      height: auto;
    }

    &.manageCampaign {
      color: #c9f8ff !important;
      background: rgba(33, 223, 253, 0.25) !important;

      svg path {
        fill: #17a2b8;
      }
    }

    &.removeCampaign {
      color: #fbe4e5 !important;
      background: rgba(255, 35, 130, 0.35);

      svg path {
        fill: #ff6188 !important;
      }
    }
  }

  .deleteItem {
    cursor: pointer;
    width: 16px;
    height: auto;

    &.deleteCompany {
      margin-left: auto;
    margin-right: 30px;
    }

    path {
      fill: whitesmoke;
    }
    &:hover{
      &.toDelete {
        path {
          transition: all 450ms ease-in;
          fill: var(--danger);
        }
      }
    }
  }
}
</style>

<style scoped lang="scss">
.custom-table {
  .accordion-item {
    margin-bottom: 0px;
    border-radius: 0px;
    border-color: #fff4;
    .accordion-item-header {
      border-bottom: 0px;
      border-color: #fff4;
    }
  }
}
</style>